import _ from 'lodash';

/**
 *
 * @param endorsement
 * @param excerpt
 */
function textFromEndorsement(endorsement, excerpt = false) {
	if (endorsement?.testimony?.text) {
		if (!endorsement.testimony.highlight) {
			if (excerpt) {
				const words = endorsement.testimony.text.split(' ');
				if (words) {
					if (words.length < 70) {
						return endorsement?.testimony?.text;
					} else {
						const slice = words.slice(0, 70);
						return slice.join(' ') + ' ...';
					}
				}
			} else return endorsement.testimony.text;
		} else {
			return endorsement.testimony.highlight;
		}
	} else if (endorsement?.qualities?.length > 0) {
		const labels = _.map(endorsement.qualities, 'label');
		let text = '';

		for (let label of labels) {
			if (excerpt && `${text}, ${label}`.length >= 120) break;
			text = text !== '' ? `${text}, ${label}` : label;
		}

		return text;
	} else return '';
}

export { textFromEndorsement };
